import LanguageTranslations from '../index'

export default [
  {
    path: '/language-translations',
    name: 'LanguageTranslations',
    component: LanguageTranslations,
    meta: {
      main_menu: true,
      slug: 'languageTranslations',
      tabPermissionKey: 'LocalDataTranslationMenu',
      page: 'languageTranslations',
      category: 'Main_data'
    }
  }
]
