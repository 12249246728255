import HTTP from '@/utils/http'
import requestHandler from '@/utils/requestHandler'
import { getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,

  state: {
    translationList: [],
    Page: {
      number: 1,
      size: 20,
      total: 0
    },
  },

  getters: {
    TranslationList: state => state.translationList,
    Page: state => ({
      ...state.Page,
      last: Math.ceil(state.Page.total / state.Page.size)
    })
  },

  actions: {
    async getAllTranslations({ commit, state }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      payload.pageNumber = PageNumber
      const loadingName = 'getAllTranslations'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('DataTranslation/local/all', payload)
        },
        success: result => {
          commit('SET_TRANSLATION_LIST', result.data)
          return result
        }
      })
      return results
    },

    async updateTranslation({ commit, dispatch }, payload) {
      const loadingName = 'updateTranslation'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.put('DataTranslation/local', payload)
        },
        success: result => result
      })
      return results
    },
  },

  mutations: {
    SET_TRANSLATION_LIST(state, data) {
      state.translationList = data.translationList
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    },
    RESET_TRANSLATIONS(state, data) {
      state.translationList = []
    }
  }
}
